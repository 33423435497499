
footer p a {
    text-decoration: none;
}

.icon {
      display: inline-block;
      stroke-width: 0;
      stroke: currentColor;
      fill: currentColor;
    }
