/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.loading {
  pointer-events: none;
  position: relative;

  &:before {
    // content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, .6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   width: 50px;
  //   height: 50px;
  //   left: calc(50% - 25px);
  //   top:  calc(50% - 25px);
  //   border-top: 5px solid rgba(#1875BC, 0.2);
  //   border-right: 5px solid rgba(#1875BC, 0.2);
  //   border-bottom: 5px solid rgba(#1875BC, 0.2);
  //   border-left: 5px solid #1875BC;
  //   border-radius: 50%;
  //   transform: translateZ(0);
  //   animation: loading-animation 0.8s infinite linear;
  //   z-index: 2;
  // }
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thumb-example {
  height: 480px;
  background-color: #000;
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;

    &.slide-1 {
      background-image:url('/images/example/1.jpg');
    }
    &.slide-2 {
      background-image:url('/images/example/2.jpg');
    }
    &.slide-3 {
      background-image:url('/images/example/4.jpg');
    }
    &.slide-4 {
      background-image:url('/images/example/5.jpg');
    }
    &.slide-5 {
      background-image:url('/images/example/6.jpg');
    }
  }

  &.gallery-top {
    height: 80%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 20px 0;
    @media screen and (max-width:600px){
      padding: 0px;
      display: none;
    }
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

.placeholder:after{
  animation: shine 1.2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  position: absolute;
  left: -0%;
  width: 280px;
  height: 100%;
  top: 0;
  opacity: 0;
  transform: rotate(0deg);
 display: none;
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(90deg, rgba(255,255,255,0) 31%, rgba(255,255,255,0.7035189075630253) 44%, rgba(255,255,255,0.6979166666666667) 51%, rgba(255,255,255,0) 67%);
}

.placeholder:active:after {
  opacity: 0;
}

@keyframes shine{
  0% {
    opacity: 1;
    left: -70%;
    transition-property: left, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: cubic-bezier(0.110, 0.495, 0.590, 0.875);
  }
  100% {
    opacity: 0;
    left: 70%;
    transition-property: left, opacity;
  }
}

.department_slide{
  width: auto !important;
}

.dep-section .swiper-container{
  overflow:visible !important;
}

.department_box {
background-color:#726C5F;
  transition: 0.1s;
  @media screen and (min-width:768px){
&:hover{
  background-color:#B1AD98;
  transform: scale(1.1);
  transition: 0.3s;
  z-index: 40;
  box-shadow: 0px 7px 19px 0px rgba(0,0,0,0.29);
}
}
}
@media screen and (min-width:768px){
.item_hover{
    transition: 0.1s;
  &:hover{
    transform: scale(1.05);
    transition: 0.2s;
    z-index: 40;
    box-shadow: 0px 7px 19px 0px rgba(0,0,0,0.29);
  }
}
}
.bm-burger-bars.line-style{
  // background-color: #fff !important;
  height: 3px !important;
}

.bm-burger-button{
  top:0px !important;
  right:0px !important
}

article{
  .article-box{
    position: relative;
  }
  @media screen and(max-width:1280px){
  &:nth-child(even) {



    .article-box{
          margin-top: 6rem;
    flex-direction: column-reverse;

    div{
      padding-top: 0px;
      h5{
        margin-bottom: 2.5rem;
      }

    }
  }
}
}
@media screen and (min-width:1280px){
  &:nth-child(3n-1) {
    .article-box{
          margin-top: 6rem;
    flex-direction: column-reverse;

    div{
      padding-top: 0px;
      h5{
        margin-bottom: 2.5rem;
      }

    }
  }
}
}

  .article-box:after {
    content: "";
    border-style:solid;
    border-width: 2px;
    width: 100%;
    height:100%;
    position: absolute;
    top: 30px;
    right:30px;
pointer-events:none;

    @media screen and (max-width: 68px){
      border-left:0px;
    }
  }

}

@media screen and(max-width:768px){
  .blog article .article-box{
    margin-bottom: 6rem;
    padding-left: 1rem;
  }
  .blog article:nth-child(even) {
    .article-box{
          margin-top: 0rem;
    flex-direction: column;

    div{
      padding-top: 0px;
      h5{
        margin-bottom: 0rem;
      }

    }
  }
}
}

.enupal-stripe-button{
  content: url('/assets/static/heart.svg');
}

.text-image{
  .text-gray p {
   color: #707070
 }
 .bg-blue h3 {
  color: #042B76 !important
}


  .image-box{
    min-height:420px;
    @media screen and (min-width:900px){
      min-height:320px;
    }
    @media screen and (min-width:1200px){
      min-height:420px;
    }
    @media screen and (min-width:1400px){
      min-height:550px;
    }
  }
}

#outer_recurring-form-input-amount, #outer_onetime-form-input-amount{
position: relative;

label{
  display:none;
}
#onetime-form-input-amount, #recurring-form-input-amount {
  border-bottom:solid 3px #042B76 !important;
  border-radius: 0px !important;
padding-left: 40px;
font-size: 24px;
background-color: transparent;
border:none;
  color: #042B76;

&:focus{
  outline:none;
  border:none;
}

}

&:before{
  content: "$";
  opacity: 0.5;
  top:22%;
  left:18px;
  font-size: 34px;
  color: #042B76;
  position: absolute;
  z-index: 10;
}
}

.required::after {
  content: "*";
  color: #d00;
  margin-left: 5px;
}

.donation-image{


    min-height:320px;
  @media screen and (min-width:800px){
    min-height:400px;
  }
  @media screen and (min-width:1100px){
    min-height:500px;
  }
}

footer  {
  .info{
    p{
      font-size: 0.9rem;
    }
    a{
    color:#fff;
    text-decoration: underline;
  }
}

}

article a {
  color: $brand-full;
  text-decoration: underline;
  font-weight: 500;

  br{
    margin-top: 15px;
  }
}

.dark-blue #outer_newsletter-form-input- button{
  background-color: #fff;
  color:#042B76;
}

.dark-blue label{
  color: #fff;
}

.dark-blue form-tab{
    color: #fff;
}

.simpleText table img{
  width: 45px;
  margin: 10px 0;
}

.loader{
  height: 100px;
  width: 100px;
  text-align: center;
  padding: 1em;
  display: inline-block;
  vertical-align: top;
}

/*
  Set the color of the icon
*/
.loader svg path,
.loader svg rect{
  fill: #FF6700;
}

.simpleText a, .tabRaw a{
  text-decoration: underline;
  color:#2D77E2;
  font-weight:bold;

  &:hover{
    color: #1655B2;
  }
}

.story a, .policies a{
  text-decoration: underline;
  color:#2D77E2;
  font-weight:bold;
  margin-right: 4px;

  &:hover{
    color: #1655B2;
  }
}

.media-description li {
    padding: 0.5rem 0;
}

.media-description.media-description li::marker,
.media-description.media-description li::-webkit-details-marker {
  color: red;
  content: "—  ";
  font-weight:bold;
}

.media-description.media-description.media-bullets-secondary li::marker,
.media-description.media-description.media-bullets-secondary li::-webkit-details-marker {
  color: blue;
  content: "—  ";
  font-weight:bold;
}
