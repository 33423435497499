// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

form {

  p {
    /*display: none;*/
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }
  a:active,
  a:hover {
    outline: 0;
  }



  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace,serif;
    font-size: 1em;
  }

  pre {
    white-space: pre-wrap;
  }

  q {
    quotes: "\201C" "\201D" "\2018" "\2019";
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  legend {
    border-bottom:2px solid #edf2f7;
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: 100%;
  }
.field{
  border: 1px #e2e8f0 solid;
  background-clip: padding-box;
  border-radius: 4px;
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
          padding: 1.3em 0.78571em 0.3rem;
}

select{

    padding: 0.7em 0.78571em 0 ;
    border: 1px #e2e8f0 solid;
  background-clip: padding-box;
  border-radius: 4px;
  height:55px !important
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,  {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.payment-form-fields input{
  border: 1px #e2e8f0 solid;
  background-clip: padding-box;
  border-radius: 4px;
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
          padding: 0.6em 0.78571em ;
}
.field input + label {
  position: absolute;
  top: 28%;
  left: 15px;
  color: #a0aec0;
  pointer-events: none;
  transition: all 0.25s ease;
}
.field input:focus, .field input:not(:placeholder-shown), .field input:-webkit-autofill {
  background-color: #fff;
}
.field input:focus + label, .field input:not(:placeholder-shown) + label, .field input:-webkit-autofill + label {
  top:  4px;
  left: 10px;
  font-size: 0.8em;
  color: #999;
}

.field:focus-within{
  border-color: #000;
}

.field textarea + label {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #a0aec0;
  pointer-events: none;
  transition: all 0.25s ease;
}
.field textarea:focus, .field textarea:not(:placeholder-shown), .field textarea:-webkit-autofill {
  background-color: #fff;
}
.field textarea:focus + label, .field textarea:not(:placeholder-shown) + label, .field textarea:-webkit-autofill + label {
  top:  4px;
  left: 10px;
  font-size: 0.8em;
  color: #999;
}
.field-held{
  position: relative;
    margin-top: 0.7rem;
}
.field-held label{
  position: absolute;
  top:  4px;
  left: 10px;
  font-size: 0.8em;
    pointer-events: none;
  color: #999;
}

  button,
  input,
  select,
  textarea {

    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    word-break: normal;
    line-height: inherit;
    font-weight:400;
    font-size: 16px;

    &:focus{
      border-color: #000;
      outline: none;
    }
  }

  .sel{
    font-weight:700;
  }

  label{
  font-size: 1em;
    line-height: 1.3em;
  margin-bottom: 3rem;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }



  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.checkmark-contain {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}


.checkmark {
    padding: 10px;
}

/* On mouse-over, add a grey background color */
.checkmark-contain:hover input ~ .checkmark {
  background-color: $brand-pale;
}

/* When the checkbox is checked, add a blue background */
.checkmark-contain input:checked ~ .checkmark {
  background-color: $brand-pale;
  border-color: $brand-full;
}

.radio-style{
  padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-mark {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 9999px;
}

/* On mouse-over, add a grey background color */
&:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
 input:checked ~ .radio-mark {
  background-color: $brand-full;
}



/* Create the checkmark/indicator (hidden when not checked) */
.radio-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
 input:checked ~ .radio-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
 .radio-mark:after {
   left: 8px;
       top: 5px;
       width: 7px;
       height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
}

.stripe-payment-intents-form{
  width: 100% !important;
.grid{
  padding: 1.5rem 1.5rem 0;
}

}

.paymentSource-form{

  button{
    margin: 1.5rem auto;
    width: 100%;
    max-width: 300px;
    width: -webkit-fill-available;
  }
}

.paypal-rest-form{
  padding: 0 1.5rem;
}

@media only screen and (min-width: 150px){
.paypal-button:not(.paypal-button-card) {
    height: 45px !important;
    min-height: 25px;
    max-height: 55px;
}
}

.card-data{
  border: 1px #e2e8f0 solid;
  border-radius: 4px;
margin: 0.7rem 1.5rem 0;
          padding: 0.6em 0.78571em ;
}

.bg-brand{
  background-color: $brand-full !important;
}

.bg-brand-10{
  background-color: $brand-10 !important;
}

.order-summary{
  @media screen and (min-width: 768px){
  min-height:calc(100vh - 80px);

}
  @media screen and (max-width: 768px){
.inner{
  max-height: 0;
  transition: 0.3s;
}
.open.inner{
  max-height:800px;
  transition: 0.3s;
}
}


}

.open .hide{
  display: flex;
}
.open .show{
  display: none;
}
