// Effects
/*effect-underline*/
a.effect-underline {
  border: none;
  position: relative;
}
a.effect-underline:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 7px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}
a.effect-underline--grey:after {
  border-bottom: $mid-grey 1px solid;
}
a.effect-underline:hover:after {
  opacity: 1;
  transform: scale(1);
}

.masonry-columns {
  columns: 3 200px;
  column-gap: 1rem;
}
.masonry-columns div {
  width: 150px;
  background: #EC985A;
  color: white;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.masonry-columns div img {
  width: 100%;
  object-fit: contain;
}

@responsive {
  .grid-rows-duo-shrink {
    grid-template-rows: max-content max-content;
  }
}
