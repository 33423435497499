





























































































































































































































































.filters-single {
    .active {}
}

.plus{
  transform: rotate(45deg);
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}

.close-plus {

.plus{
  transform: rotate(0deg);
  transition: 0.2s;
    transition-timing-function: ease-in-out;
}
}


.filter-outer {
    @media screen and (max-width: 767px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

        &.open {
            max-height: 2000px;
        }
    }
}

.filter-group {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

    .filter-wrapper.open & {
        opacity: 1;
        max-height: 800px;
        overflow-y: scroll;
        position: relative;



    }


}

.filter-wrapper {
   .filters-single.active, .filter-other.active{
    padding-left: 20px;
    position: relative;
    font-weight: bold;

    &:before{
      content: "";
      width: 5px;
      height: 5px;
      background-color: #fff;
      position: absolute;
      top:0;
      bottom:0;
      border-radius: 99px;
      margin-right: 8px;
      margin: auto;
      margin-left: -15px;
    }
  }
  .filter-single.active{
    position: relative;
    font-weight: bold;
      padding-left: 26px;
      &:before  {
      content:"";
      position: absolute;
      width:20px;
      background-repeat: no-repeat;
      height:20px;
      margin-right: 8px;
      background-position:center;
      pointer-events: none;
      cursor:pointer;
      left:0;
      background-image:url('/assets/static/close.svg');
  }
}
    h3 {
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            pointer-events: none;
            transition: transform 0.2s ease;
            path {
                stroke: currentColor;
            }
        }
    }
    &.open {
        h3 {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}


