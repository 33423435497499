.minicart {
	visibility: hidden;
	transition: visibility .3s ease;
	transition-delay: .3s;
	
	&.open {
		visibility: visible;
		transition-delay: 0s;
	}
}

.minicart-sidebar {
	transition: transform .3s ease;
	transform: translateX(100%);

	.minicart.open & {
		transform: translate(0);
	}
}

.minicart-shade {
	opacity: 0;
	transition: opacity .3s ease;

	.minicart.open & {
		opacity: .25;
	}
}