.banner_section{
  height: 600px;
}


.banner_overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
pointer-events: none;

&.banner_top{
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5858718487394958) 100%);

&.light{
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.5858718487394958) 100%);
}

  }

  &.banner_bottom{
    background: linear-gradient(0deg, rgba(0,0,0,0.5886729691876751) 0%, rgba(0,0,0,0) 64%, rgba(0,0,0,0) 100%);
&.light{
  background: linear-gradient(0deg, rgba(255,255,255,0.5886729691876751) 0%, rgba(255,255,255,0) 64%, rgba(255,255,255,0) 100%);
}

  }

  &.banner_full{
    background: rgba(0,0,0,0.4);

    &.light{
      background: rgba(255,255,255,0.4);
        }
    }
  }



.full-nav-list{
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap:   4px;
  -moz-column-count:    5;
  -moz-column-gap:      0px;
  column-count:         2;
  column-gap:           4px;
}

.full-nav-list div{
  width: 100% !important;
height: auto !important;
}
