.bg--light {
  background: $light-grey;
  color: $text-color;
}
.background--medium {
  background: $mid-grey;
  color: $dark-grey;
}
.background--dark {
  background: $dark-grey;
  color: $light-grey;
}
.background--highlight {
  background: $highlight-color;
  color: $dark-grey;
}
.background--alt {
  background: $bg-color-alt;
  color: $text-color-alt;
}

.notification-message p {
    padding: 0;
    margin: 0;
    font-size: inherit;
}

.scrolling-text {
    position: relative;
    width: max-content;
    animation: marquee-text 40s linear infinite;
}

@keyframes marquee-text {
    0% {
        transform: translateX(30%);
    }

    100% {
        transform: translateX(-100%);
    }
}
